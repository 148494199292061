import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import classNames from "classnames"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Hidden from "@material-ui/core/Hidden"

import PlayIcon from "../images/icons/play-icon.svg"

const styles = createStyles({
  game: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    textAlign: "center",
    padding: 70,
    paddingBottom: 80,
    "@media (max-width: 1279.95px)": {
      gridTemplateColumns: "100%",
      gap: 20,
    },
    "@media (max-width: 599.95px)": {
      padding: "40px 20px",
    },
  },
  columnContainer: {
    display: "flex",
    width: "100%",
    "@media (max-width: 1279.95px)": {
      order: "0 !important",
      justifyContent: "center !important",
    },
  },
  column: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    fontSize: "1rem",
    maxWidth: 556,
    margin: "0 50px",
    "@media (max-width: 1279.95px)": {
      margin: 0,
      maxWidth: "100%",
    },
  },
  rightColumn: {
    "@media (max-width: 1279.95px)": {
      flexDirection: "row",
      justifyContent: "center",
    },
  },
  details: {
    maxWidth: 400,
    "@media (max-width: 1279.95px)": {
      maxWidth: "100%",
    },
  },
  name: {
    fontSize: "1.78rem",
    lineHeight: "2.67rem",
    fontWeight: 900,
    color: "#606060",
    marginBottom: 30,
    textTransform: "uppercase",
  },
  slogan: {
    lineHeight: "1.38rem",
    color: "#A8A8A8",
    marginBottom: 20,
  },
  description: {
    lineHeight: "1.56rem",
    color: "#898989",
    marginBottom: 30,
  },
  video: {
    position: "relative",
    display: "flex !important" as "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 25,
    overflow: "hidden",
    width: "556px !important",
    height: "318px !important",
    maxWidth: "100%",
    "@media (max-width: 599.95px)": {
      height: "228px !important",
    },
  },
  videoOverlay: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    background:
      "linear-gradient(0deg, rgba(33, 33, 33, 0.6), rgba(33, 33, 33, 0.6))",
    mixBlendMode: "multiply",
  },
  playIcon: {
    position: "relative",
  },
  logo: {
    maxWidth: "100%",
  },
  appStoreIcon: {
    maxWidth: "100%",
    height: 200,
    "@media (max-width: 399.95px)": {
      height: "120px !important",
    },
  },
  storeButtonsContainer: {
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 1279.95px)": {
      flexDirection: "column",
      alignItems: "center",
      marginLeft: 10,
    },
    "@media (max-width: 599.95px)": {
      width: 200,
    },
  },
  storeButton: {
    margin: "0 10px",
    width: 212,
    height: 75,
    "@media (max-width: 1279.95px)": {
      margin: "5px 0",
    },
    "@media (max-width: 599.95px)": {
      height: 60,
      width: 200,
    },
    "@media (max-width: 399.95px)": {
      height: 50,
      width: 150,
    },
  },
  storeButtonImg: {
    width: "100% !important",
    height: "100% !important",
    display: "flex !important",
  },
  zeptolabButton: {
    position: "absolute",
    bottom: 0,
    right: "-10%",
    width: "150px !important",
    height: "125px !important",
  },
})

export interface Game {
  name: string
  slogan: string
  description: string
  videoThumbnailPath: string
  videoPath: string
  logoPath: string
  appStoreLogoPath: string
  googlePlay?: string
  appleStore?: string
  zeptolab?: boolean
}
export interface Props {
  game: Game
  inverted?: boolean
}

type PropsAndStyles = Props & WithStyles<typeof styles>

const GameDetails = ({ classes, game, inverted }: PropsAndStyles) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fixed(quality: 100) {
                  width
                  height
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {
        name,
        slogan,
        description,
        videoThumbnailPath,
        videoPath,
        logoPath,
        appStoreLogoPath,
        googlePlay,
        appleStore,
        zeptolab,
      } = game

      const videoThumbnail = data.images.edges.find(
        image => image.node.relativePath === videoThumbnailPath
      )

      const logoImg = data.images.edges.find(
        image => image.node.relativePath === logoPath
      )
      const appStoreLogo = data.images.edges.find(
        image => image.node.relativePath === appStoreLogoPath
      )

      const googlePlayIcon = data.images.edges.find(
        image => image.node.relativePath === "icons/google-play.png"
      )

      const appleStoreIcon = data.images.edges.find(
        image => image.node.relativePath === "icons/apple-store.png"
      )

      const zeptolabIcon = data.images.edges.find(
        image => image.node.relativePath === "icons/zeptolab.png"
      )

      return (
        <div
          className={classes.game}
          style={{ background: inverted ? "#FFFFFF" : "#F4F4F4" }}
        >
          <div
            className={classNames(classes.columnContainer)}
            style={{
              order: inverted ? 1 : 0,
              justifyContent: inverted ? "flex-start" : "flex-end",
            }}
          >
            <div className={classes.column}>
              <div className={classes.details}>
                <Hidden lgUp>
                  <Img
                    imgStyle={{ objectFit: "contain" }}
                    className={classNames(
                      logoImg?.node.childImageSharp.fixed,
                      classes.logo
                    )}
                    fixed={logoImg?.node.childImageSharp.fixed}
                  />
                </Hidden>

                <Hidden mdDown>
                  <div className={classes.name}>{name}</div>
                </Hidden>
                <div className={classes.slogan}>{slogan}</div>
                <div className={classes.description}>{description}</div>
              </div>

              <BackgroundImage
                className={classNames(
                  videoThumbnail?.node.childImageSharp.fixed,
                  classes.video
                )}
                fixed={videoThumbnail?.node.childImageSharp.fixed}
              >
                <div className={classes.videoOverlay} />
                {/* <PlayIcon className={classes.playIcon} /> */}
              </BackgroundImage>
            </div>
          </div>

          <div
            className={classes.columnContainer}
            style={{
              justifyContent: inverted ? "flex-end" : "flex-start",
            }}
          >
            <div className={classNames(classes.column, classes.rightColumn)}>
              <Hidden mdDown>
                <Img
                  imgStyle={{ objectFit: "contain" }}
                  className={classNames(
                    logoImg?.node.childImageSharp.fixed,
                    classes.logo
                  )}
                  fixed={logoImg?.node.childImageSharp.fixed}
                />
              </Hidden>

              <Img
                className={classNames(
                  appStoreLogo?.node.childImageSharp.fixed,
                  classes.appStoreIcon
                )}
                fixed={appStoreLogo?.node.childImageSharp.fixed}
                imgStyle={{ objectFit: "contain" }}
              />

              <div className={classes.storeButtonsContainer}>
                {appleStore && (
                  <a
                    className={classes.storeButton}
                    href={appleStore}
                    target="_blank"
                  >
                    <Img
                      className={classNames(
                        appleStoreIcon?.node.childImageSharp.fixed,
                        classes.storeButtonImg
                      )}
                      fixed={appleStoreIcon?.node.childImageSharp.fixed}
                      imgStyle={{ objectFit: "contain" }}
                    />
                  </a>
                )}

                {googlePlay && (
                  <a
                    className={classes.storeButton}
                    href={googlePlay}
                    target="_blank"
                  >
                    <Img
                      className={classNames(
                        googlePlayIcon?.node.childImageSharp.fixed,
                        classes.storeButtonImg
                      )}
                      fixed={googlePlayIcon?.node.childImageSharp.fixed}
                      imgStyle={{
                        objectFit: "contain",
                      }}
                    />
                  </a>
                )}

                {zeptolab && (
                  <Hidden mdDown>
                    <a
                      className={classNames(
                        classes.storeButton,
                        classes.zeptolabButton
                      )}
                    >
                      <Img
                        className={classes.storeButtonImg}
                        fixed={zeptolabIcon?.node.childImageSharp.fixed}
                        imgStyle={{ objectFit: "contain", width: 150 }}
                      />
                    </a>
                  </Hidden>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default withStyles(styles)(GameDetails)
